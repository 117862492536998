var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('TabView',[_c('TabPanel',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(" Ativos ("+_vm._s(_vm.ativosList.length)+") ")])]},proxy:true}])},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","loading":_vm.loading,"value":_vm.ativosList,"dataKey":"id","filters":_vm.filtros,"globalFilterFields":[
                'rubrica.rubrica',
                'mesReferencia',
                'anoReferencia',
                'numeroContrato',
                'statusConsignacao' ],"filterDisplay":"menu","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} consignações","responsiveLayout":"scroll"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma consignação encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"flex flex-column sm:flex-row"},[_c('span',{staticClass:"p-input-icon-left mb-2 mr-2"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"p-button-outlined mb-2",attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})],1)]},proxy:true}])},[_c('Column',{attrs:{"field":"id","header":"ID"}}),_c('Column',{attrs:{"sortable":true,"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(('0' + data.mesReferencia).slice(-2))+"/"+_vm._s(data.anoReferencia)+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"rubrica","header":"Rubrica"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.rubrica : '')+" - "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.nome : '')+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"numeroContrato","header":"Contrato"}}),_c('Column',{attrs:{"header":"Prazo"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(data.prazoRestante + data.prazoTotal == 0 ? '-' : data.prazoRestante + '/')+_vm._s(data.prazoTotal == 0 ? '' : data.prazoTotal)+" ")]}}])}),_c('Column',{attrs:{"header":"Valor da Parcela"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-end flex-wrap card-container"},[_c('div',{staticClass:"flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.valorParcela))+" ")])])]}}])}),_c('Column',{attrs:{"sortable":true,"field":"statusConsignacao","header":"Situação","filterMenuStyle":{ width: '14rem' },"styles":{ 'min-width': '12rem' }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [(data.statusConsignacao === 'PENDENTE')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip pendente",attrs:{"label":"Pendente"}}):_vm._e(),(data.statusConsignacao === 'ATIVA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip ativa",attrs:{"label":"Ativa"}}):_vm._e(),(data.statusConsignacao === 'QUITADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip quitada",attrs:{"label":"Quitada"}}):_vm._e(),(data.statusConsignacao === 'CANCELADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SUSPENSA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip aguardando-supervisor",attrs:{"label":"Aguardando Supervisor"}}):_vm._e(),(
                      data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-aprovada-supervisor",attrs:{"label":"Não Aprovada Supervisor"}}):_vm._e(),(data.statusConsignacao === 'BAIXADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip baixada",attrs:{"label":"Baixada"}}):_vm._e(),(data.statusConsignacao === 'NAO_DESCONTADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-descontada",attrs:{"label":"Não descontada"}}):_vm._e(),(
                      data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SOLICITADA_SUSPENSAO')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'MOVIMENTO_PARCIAL')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Mov. Parcial"}}):_vm._e(),(data.statusConsignacao === 'FORA_MARGEM')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Fora da margem"}}):_vm._e(),(data.statusConsignacao === 'DESCONTO_A_MENOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Desc. a Menor"}}):_vm._e()]}}])}),_c('Column',{attrs:{"header":"Cadastrado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.auditMetadata.cadastradoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Cancelado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.canceladoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-around"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-success",attrs:{"icon":"pi pi-eye"},on:{"click":function($event){return _vm.exibirDetalheConsignacao(data)}}}),(_vm.exibirBotaoCancelar(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Cancelar/Suspender'),expression:"'Cancelar/Suspender'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-danger",attrs:{"icon":"pi pi-times"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e(),(
                        data.prazoRestante + data.prazoTotal == 0 &&
                        data.statusConsignacao === 'ATIVA'
                      )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Alterar mensalidade'),expression:"'Alterar mensalidade'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-pencil"},on:{"click":function($event){return _vm.alterarMensalidade(data)}}}):_vm._e(),(_vm.exibirBotaoReincluir(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Reincluir Consignação'),expression:"'Reincluir Consignação'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-replay"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e()],1)]}}])})],1)],1),_c('TabPanel',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(" Quitados ("+_vm._s(_vm.quitadosList.length)+") ")])]},proxy:true}])},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","loading":_vm.loading,"value":_vm.quitadosList,"dataKey":"id","filters":_vm.filtros,"globalFilterFields":[
                'rubrica.rubrica',
                'mesReferencia',
                'anoReferencia',
                'numeroContrato',
                'statusConsignacao' ],"filterDisplay":"menu","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} consignações","responsiveLayout":"scroll"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma consignação encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"flex flex-column sm:flex-row"},[_c('span',{staticClass:"p-input-icon-left mb-2 mr-2"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"p-button-outlined mb-2",attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})],1)]},proxy:true}])},[_c('Column',{attrs:{"field":"id","header":"ID"}}),_c('Column',{attrs:{"sortable":true,"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(('0' + data.mesReferencia).slice(-2))+"/"+_vm._s(data.anoReferencia)+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"rubrica","header":"Rubrica"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.rubrica : '')+" - "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.nome : '')+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"numeroContrato","header":"Contrato"}}),_c('Column',{attrs:{"header":"Prazo"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(data.prazoRestante + data.prazoTotal == 0 ? '-' : data.prazoRestante + '/')+_vm._s(data.prazoTotal == 0 ? '' : data.prazoTotal)+" ")]}}])}),_c('Column',{attrs:{"header":"Valor da Parcela"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-end flex-wrap card-container"},[_c('div',{staticClass:"flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.valorParcela))+" ")])])]}}])}),_c('Column',{attrs:{"sortable":true,"field":"statusConsignacao","header":"Situação","filterMenuStyle":{ width: '14rem' },"styles":{ 'min-width': '12rem' }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [(data.statusConsignacao === 'PENDENTE')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip pendente",attrs:{"label":"Pendente"}}):_vm._e(),(data.statusConsignacao === 'ATIVA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip ativa",attrs:{"label":"Ativa"}}):_vm._e(),(data.statusConsignacao === 'QUITADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip quitada",attrs:{"label":"Quitada"}}):_vm._e(),(data.statusConsignacao === 'CANCELADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SUSPENSA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip aguardando-supervisor",attrs:{"label":"Aguardando Supervisor"}}):_vm._e(),(
                      data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-aprovada-supervisor",attrs:{"label":"Não Aprovada Supervisor"}}):_vm._e(),(data.statusConsignacao === 'BAIXADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip baixada",attrs:{"label":"Baixada"}}):_vm._e(),(data.statusConsignacao === 'NAO_DESCONTADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-descontada",attrs:{"label":"Não descontada"}}):_vm._e(),(
                      data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SOLICITADA_SUSPENSAO')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'MOVIMENTO_PARCIAL')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Mov. Parcial"}}):_vm._e(),(data.statusConsignacao === 'FORA_MARGEM')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Fora da margem"}}):_vm._e(),(data.statusConsignacao === 'DESCONTO_A_MENOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Desc. a Menor"}}):_vm._e()]}}])}),_c('Column',{attrs:{"header":"Cadastrado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.auditMetadata.cadastradoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Cancelado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.canceladoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-around"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-success",attrs:{"icon":"pi pi-eye"},on:{"click":function($event){return _vm.exibirDetalheConsignacao(data)}}}),(_vm.exibirBotaoCancelar(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Cancelar/Suspender'),expression:"'Cancelar/Suspender'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-danger",attrs:{"icon":"pi pi-times"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e(),(
                        data.prazoRestante + data.prazoTotal == 0 &&
                        data.statusConsignacao === 'ATIVA'
                      )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Alterar mensalidade'),expression:"'Alterar mensalidade'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-pencil"},on:{"click":function($event){return _vm.alterarMensalidade(data)}}}):_vm._e(),(_vm.exibirBotaoReincluir(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Reincluir Consignação'),expression:"'Reincluir Consignação'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-replay"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e()],1)]}}])})],1)],1),_c('TabPanel',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(" Cancelados ("+_vm._s(_vm.canceladosList.length)+") ")])]},proxy:true}])},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","loading":_vm.loading,"value":_vm.canceladosList,"dataKey":"id","filters":_vm.filtros,"globalFilterFields":[
                'rubrica.rubrica',
                'mesReferencia',
                'anoReferencia',
                'numeroContrato',
                'statusConsignacao' ],"filterDisplay":"menu","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} consignações","responsiveLayout":"scroll"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma consignação encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"flex flex-column sm:flex-row"},[_c('span',{staticClass:"p-input-icon-left mb-2 mr-2"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"p-button-outlined mb-2",attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})],1)]},proxy:true}])},[_c('Column',{attrs:{"field":"id","header":"ID"}}),_c('Column',{attrs:{"sortable":true,"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(('0' + data.mesReferencia).slice(-2))+"/"+_vm._s(data.anoReferencia)+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"rubrica","header":"Rubrica"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.rubrica : '')+" - "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.nome : '')+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"numeroContrato","header":"Contrato"}}),_c('Column',{attrs:{"header":"Prazo"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(data.prazoRestante + data.prazoTotal == 0 ? '-' : data.prazoRestante + '/')+_vm._s(data.prazoTotal == 0 ? '' : data.prazoTotal)+" ")]}}])}),_c('Column',{attrs:{"header":"Valor da Parcela"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-end flex-wrap card-container"},[_c('div',{staticClass:"flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.valorParcela))+" ")])])]}}])}),_c('Column',{attrs:{"sortable":true,"field":"statusConsignacao","header":"Situação","filterMenuStyle":{ width: '14rem' },"styles":{ 'min-width': '12rem' }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [(data.statusConsignacao === 'PENDENTE')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip pendente",attrs:{"label":"Pendente"}}):_vm._e(),(data.statusConsignacao === 'ATIVA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip ativa",attrs:{"label":"Ativa"}}):_vm._e(),(data.statusConsignacao === 'QUITADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip quitada",attrs:{"label":"Quitada"}}):_vm._e(),(data.statusConsignacao === 'CANCELADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SUSPENSA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip aguardando-supervisor",attrs:{"label":"Aguardando Supervisor"}}):_vm._e(),(
                      data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-aprovada-supervisor",attrs:{"label":"Não Aprovada Supervisor"}}):_vm._e(),(data.statusConsignacao === 'BAIXADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip baixada",attrs:{"label":"Baixada"}}):_vm._e(),(data.statusConsignacao === 'NAO_DESCONTADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-descontada",attrs:{"label":"Não descontada"}}):_vm._e(),(
                      data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SOLICITADA_SUSPENSAO')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'MOVIMENTO_PARCIAL')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Mov. Parcial"}}):_vm._e(),(data.statusConsignacao === 'FORA_MARGEM')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Fora da margem"}}):_vm._e(),(data.statusConsignacao === 'DESCONTO_A_MENOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Desc. a Menor"}}):_vm._e()]}}])}),_c('Column',{attrs:{"header":"Cadastrado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.auditMetadata.cadastradoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Cancelado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.canceladoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-around"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-success",attrs:{"icon":"pi pi-eye"},on:{"click":function($event){return _vm.exibirDetalheConsignacao(data)}}}),(_vm.exibirBotaoCancelar(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Cancelar/Suspender'),expression:"'Cancelar/Suspender'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-danger",attrs:{"icon":"pi pi-times"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e(),(
                        data.prazoRestante + data.prazoTotal == 0 &&
                        data.statusConsignacao === 'ATIVA'
                      )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Alterar mensalidade'),expression:"'Alterar mensalidade'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-pencil"},on:{"click":function($event){return _vm.alterarMensalidade(data)}}}):_vm._e(),(_vm.exibirBotaoReincluir(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Reincluir Consignação'),expression:"'Reincluir Consignação'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-replay"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e()],1)]}}])})],1)],1),_c('TabPanel',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(" Outros ("+_vm._s(_vm.outrosList.length)+") ")])]},proxy:true}])},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","loading":_vm.loading,"value":_vm.outrosList,"dataKey":"id","filters":_vm.filtros,"globalFilterFields":[
                'rubrica.rubrica',
                'mesReferencia',
                'anoReferencia',
                'numeroContrato',
                'statusConsignacao' ],"filterDisplay":"menu","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":_vm.rowsPerPageOptions,"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} consignações","responsiveLayout":"scroll"},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma consignação encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"flex flex-column sm:flex-row"},[_c('span',{staticClass:"p-input-icon-left mb-2 mr-2"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Pesquisar"},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('Button',{staticClass:"p-button-outlined mb-2",attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Limpar"},on:{"click":_vm.limparFiltro}})],1)]},proxy:true}])},[_c('Column',{attrs:{"field":"id","header":"ID"}}),_c('Column',{attrs:{"sortable":true,"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(('0' + data.mesReferencia).slice(-2))+"/"+_vm._s(data.anoReferencia)+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"rubrica","header":"Rubrica"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.rubrica : '')+" - "+_vm._s(typeof data.rubrica !== 'undefined' ? data.rubrica.nome : '')+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"numeroContrato","header":"Contrato"}}),_c('Column',{attrs:{"header":"Prazo"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(data.prazoRestante + data.prazoTotal == 0 ? '-' : data.prazoRestante + '/')+_vm._s(data.prazoTotal == 0 ? '' : data.prazoTotal)+" ")]}}])}),_c('Column',{attrs:{"header":"Valor da Parcela"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-end flex-wrap card-container"},[_c('div',{staticClass:"flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.valorParcela))+" ")])])]}}])}),_c('Column',{attrs:{"sortable":true,"field":"statusConsignacao","header":"Situação","filterMenuStyle":{ width: '14rem' },"styles":{ 'min-width': '12rem' }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var data = ref.data;
return [(data.statusConsignacao === 'PENDENTE')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip pendente",attrs:{"label":"Pendente"}}):_vm._e(),(data.statusConsignacao === 'ATIVA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip ativa",attrs:{"label":"Ativa"}}):_vm._e(),(data.statusConsignacao === 'QUITADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip quitada",attrs:{"label":"Quitada"}}):_vm._e(),(data.statusConsignacao === 'CANCELADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SUSPENSA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip aguardando-supervisor",attrs:{"label":"Aguardando Supervisor"}}):_vm._e(),(
                      data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-aprovada-supervisor",attrs:{"label":"Não Aprovada Supervisor"}}):_vm._e(),(data.statusConsignacao === 'BAIXADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip baixada",attrs:{"label":"Baixada"}}):_vm._e(),(data.statusConsignacao === 'NAO_DESCONTADA')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip não-descontada",attrs:{"label":"Não descontada"}}):_vm._e(),(
                      data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                    )?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip cancelada",attrs:{"label":"Cancelada"}}):_vm._e(),(data.statusConsignacao === 'SOLICITADA_SUSPENSAO')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip suspensa",attrs:{"label":"Suspensa"}}):_vm._e(),(data.statusConsignacao === 'MOVIMENTO_PARCIAL')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Mov. Parcial"}}):_vm._e(),(data.statusConsignacao === 'FORA_MARGEM')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Fora da margem"}}):_vm._e(),(data.statusConsignacao === 'DESCONTO_A_MENOR')?_c('Chip',{staticClass:"mr-2 mb-2 custom-chip foda-da-margem",attrs:{"label":"Desc. a Menor"}}):_vm._e()]}}])}),_c('Column',{attrs:{"header":"Cadastrado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.auditMetadata.cadastradoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Cancelado Em"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarData")(data.canceladoEm))+" ")]}}])}),_c('Column',{attrs:{"header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var data = ref.data;
return [_c('div',{staticClass:"flex justify-content-around"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-success",attrs:{"icon":"pi pi-eye"},on:{"click":function($event){return _vm.exibirDetalheConsignacao(data)}}}),(_vm.exibirBotaoCancelar(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Cancelar/Suspender'),expression:"'Cancelar/Suspender'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-danger",attrs:{"icon":"pi pi-times"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e(),(
                        data.prazoRestante + data.prazoTotal == 0 &&
                        data.statusConsignacao === 'ATIVA'
                      )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Alterar mensalidade'),expression:"'Alterar mensalidade'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-pencil"},on:{"click":function($event){return _vm.alterarMensalidade(data)}}}):_vm._e(),(_vm.exibirBotaoReincluir(data))?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Reincluir Consignação'),expression:"'Reincluir Consignação'",modifiers:{"left":true}}],staticClass:"mt-2 mr-2 p-button-rounded p-button-warning",attrs:{"icon":"pi pi-replay"},on:{"click":function($event){return _vm.cancelarConsignacao(data)}}}):_vm._e()],1)]}}])})],1)],1)],1),_c('HistoricoDialog',{attrs:{"visible":_vm.showHistoricoDialog,"historico":_vm.historico},on:{"update:visible":function($event){_vm.showHistoricoDialog=$event}}})]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }