<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <TabView>
            <TabPanel>
              <template #header>
                <span> Ativos ({{ ativosList.length }}) </span>
              </template>
              <!-- 1 - Ativa, 2 - Pendente, 3 -  Suspensa, 9 - Nao Descontada, 10 - Desc. a Menor, 12 - Fora da Margem,  14 - Mov. Parcial  -->
              <DataTable
                class="p-datatable-sm"
                :paginator="true"
                :rows="5"
                stripedRows
                :loading="loading"
                :value="ativosList"
                dataKey="id"
                :filters.sync="filtros"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'mesReferencia',
                  'anoReferencia',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll">
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>

                <Column field="id" header="ID"></Column>
                <Column :sortable="true" field="mesReferencia" header="Mês/Ano">
                  <template #body="{ data }">
                    {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                      data.anoReferencia
                    }}
                  </template>
                </Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                      <Button
                        v-if="exibirBotaoCancelar(data)"
                        v-tooltip.left="'Cancelar/Suspender'"
                        icon="pi pi-times"
                        class="mt-2 mr-2 p-button-rounded p-button-danger"
                        @click="cancelarConsignacao(data)" />
                      <Button
                        v-if="
                          data.prazoRestante + data.prazoTotal == 0 &&
                          data.statusConsignacao === 'ATIVA'
                        "
                        v-tooltip.left="'Alterar mensalidade'"
                        icon="pi pi-pencil"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="alterarMensalidade(data)" />

                      <Button
                        v-if="exibirBotaoReincluir(data)"
                        v-tooltip.left="'Reincluir Consignação'"
                        icon="pi pi-replay"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="cancelarConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span> Quitados ({{ quitadosList.length }}) </span>
              </template>
              <!--6 - Aguardando Supervisor, 7 - Nao Aprovada Supervisor, 8 - Baixada, 11 - Cancelamento, 13 - Solicitado Suspensao, 99 - Carga  -->
              <DataTable
                class="p-datatable-sm"
                :paginator="true"
                :rows="5"
                stripedRows
                :loading="loading"
                :value="quitadosList"
                dataKey="id"
                :filters.sync="filtros"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'mesReferencia',
                  'anoReferencia',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll">
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <Column field="id" header="ID"></Column>
                <Column :sortable="true" field="mesReferencia" header="Mês/Ano">
                  <template #body="{ data }">
                    {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                      data.anoReferencia
                    }}
                  </template>
                </Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                      <Button
                        v-if="exibirBotaoCancelar(data)"
                        v-tooltip.left="'Cancelar/Suspender'"
                        icon="pi pi-times"
                        class="mt-2 mr-2 p-button-rounded p-button-danger"
                        @click="cancelarConsignacao(data)" />
                      <Button
                        v-if="
                          data.prazoRestante + data.prazoTotal == 0 &&
                          data.statusConsignacao === 'ATIVA'
                        "
                        v-tooltip.left="'Alterar mensalidade'"
                        icon="pi pi-pencil"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="alterarMensalidade(data)" />

                      <Button
                        v-if="exibirBotaoReincluir(data)"
                        v-tooltip.left="'Reincluir Consignação'"
                        icon="pi pi-replay"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="cancelarConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span> Cancelados ({{ canceladosList.length }}) </span>
              </template>
              <!-- 4 - Cancelada, 5 - Quitada  -->
              <DataTable
                class="p-datatable-sm"
                :paginator="true"
                :rows="5"
                stripedRows
                :loading="loading"
                :value="canceladosList"
                dataKey="id"
                :filters.sync="filtros"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'mesReferencia',
                  'anoReferencia',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll">
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <Column field="id" header="ID"></Column>
                <Column :sortable="true" field="mesReferencia" header="Mês/Ano">
                  <template #body="{ data }">
                    {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                      data.anoReferencia
                    }}
                  </template>
                </Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                      <Button
                        v-if="exibirBotaoCancelar(data)"
                        v-tooltip.left="'Cancelar/Suspender'"
                        icon="pi pi-times"
                        class="mt-2 mr-2 p-button-rounded p-button-danger"
                        @click="cancelarConsignacao(data)" />
                      <Button
                        v-if="
                          data.prazoRestante + data.prazoTotal == 0 &&
                          data.statusConsignacao === 'ATIVA'
                        "
                        v-tooltip.left="'Alterar mensalidade'"
                        icon="pi pi-pencil"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="alterarMensalidade(data)" />

                      <Button
                        v-if="exibirBotaoReincluir(data)"
                        v-tooltip.left="'Reincluir Consignação'"
                        icon="pi pi-replay"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="cancelarConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span> Outros ({{ outrosList.length }}) </span>
              </template>
              <!--6 - Aguardando Supervisor, 7 - Nao Aprovada Supervisor, 8 - Baixada, 11 - Cancelamento, 13 - Solicitado Suspensao, 99 - Carga  -->
              <DataTable
                class="p-datatable-sm"
                :paginator="true"
                :rows="5"
                stripedRows
                :loading="loading"
                :value="outrosList"
                dataKey="id"
                :filters.sync="filtros"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'mesReferencia',
                  'anoReferencia',
                  'numeroContrato',
                  'statusConsignacao',
                ]"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                responsiveLayout="scroll">
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <Column field="id" header="ID"></Column>
                <Column :sortable="true" field="mesReferencia" header="Mês/Ano">
                  <template #body="{ data }">
                    {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                      data.anoReferencia
                    }}
                  </template>
                </Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="statusConsignacao"
                  header="Situação"
                  :filterMenuStyle="{ width: '14rem' }"
                  :styles="{ 'min-width': '12rem' }">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.statusConsignacao === 'PENDENTE'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.statusConsignacao === 'ATIVA'"
                      label="Ativa"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.statusConsignacao === 'QUITADA'"
                      label="Quitada"
                      class="mr-2 mb-2 custom-chip quitada" />
                    <Chip
                      v-if="data.statusConsignacao === 'CANCELADA'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SUSPENSA'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'"
                      label="Aguardando Supervisor"
                      class="mr-2 mb-2 custom-chip aguardando-supervisor" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
                      "
                      label="Não Aprovada Supervisor"
                      class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
                    <Chip
                      v-if="data.statusConsignacao === 'BAIXADA'"
                      label="Baixada"
                      class="mr-2 mb-2 custom-chip baixada" />
                    <Chip
                      v-if="data.statusConsignacao === 'NAO_DESCONTADA'"
                      label="Não descontada"
                      class="mr-2 mb-2 custom-chip não-descontada" />
                    <Chip
                      v-if="
                        data.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.statusConsignacao === 'SOLICITADA_SUSPENSAO'"
                      label="Suspensa"
                      class="mr-2 mb-2 custom-chip suspensa" />
                    <Chip
                      v-if="data.statusConsignacao === 'MOVIMENTO_PARCIAL'"
                      label="Mov. Parcial"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'FORA_MARGEM'"
                      label="Fora da margem"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                    <Chip
                      v-if="data.statusConsignacao === 'DESCONTO_A_MENOR'"
                      label="Desc. a Menor"
                      class="mr-2 mb-2 custom-chip foda-da-margem" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{ data.auditMetadata.cadastradoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Cancelado Em">
                  <template #body="{ data }">
                    {{ data.canceladoEm | formatarData }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <div class="flex justify-content-around">
                      <Button
                        v-tooltip.left="'Detalhes'"
                        icon="pi pi-eye"
                        class="mt-2 mr-2 p-button-rounded p-button-success"
                        @click="exibirDetalheConsignacao(data)" />
                      <Button
                        v-if="exibirBotaoCancelar(data)"
                        v-tooltip.left="'Cancelar/Suspender'"
                        icon="pi pi-times"
                        class="mt-2 mr-2 p-button-rounded p-button-danger"
                        @click="cancelarConsignacao(data)" />
                      <Button
                        v-if="
                          data.prazoRestante + data.prazoTotal == 0 &&
                          data.statusConsignacao === 'ATIVA'
                        "
                        v-tooltip.left="'Alterar mensalidade'"
                        icon="pi pi-pencil"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="alterarMensalidade(data)" />

                      <Button
                        v-if="exibirBotaoReincluir(data)"
                        v-tooltip.left="'Reincluir Consignação'"
                        icon="pi pi-replay"
                        class="mt-2 mr-2 p-button-rounded p-button-warning"
                        @click="cancelarConsignacao(data)" />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
          </TabView>
          <HistoricoDialog
            :visible.sync="showHistoricoDialog"
            :historico="historico" />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import { servidorStore } from '@/stores/servidor'
import ConsignacaoService from '@/service/ConsignacaoService'
import HistoricoDialog from '@/components/shared/modal/historicoDialog.vue'
import MatriculaService from '@/service/MatriculaService'

export default {
  components: {
    HistoricoDialog,
  },

  setup() {
    const store = servidorStore()
    return { store }
  },

  data() {
    return {
      ativos: [
        'ATIVA',
        'PENDENTE',
        'SUSPENSA',
        'NAO_DESCONTADA',
        'DESCONTO_A_MENOR',
        'FORA_DA_MARGEM',
        'SOLICITADO_SUSPENSAO',
        'MOVIMENTO_PARCIAL',
      ],
      cancelados: ['CANCELADA', 'CANCELAMENTO', 'SOLICITADO_CANCELAMENTO'],
      quitados: ['QUITADA'],
      quitadosList: [],
      // outros: ["AGUARDANDO_SUPERVISOR", "NAO_APROVADA_SUPERVISOR", "BAIXADA", "CARGA"],
      ativosList: [],
      outrosList: [],
      canceladosList: [],
      filtros: {},
      consignacao: {
        rubrica: {},
      },
      idMatricula: this.$route.params.id,
      loading: false,
      consignacaoDetalheDialog: false,
      showHistoricoDialog: false,
      historico: [],
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.service = new ConsignacaoService(this.$http)
    this.matriculaService = new MatriculaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.loading = true
    this.carregaConsignacoes()
  },

  methods: {
    carregaConsignacoes() {
      this.matriculaService
        .getMatricula(this.store.dados.id)
        .then((res) => {
          this.store.$reset()
          this.store.dados = res
          this.store.configuracoes = res.configuracoes
          this.loading = false
          this.loadingSpinner = false
          this.store.checkValidation()
          this.filtrarStatusConsignacao()
        })
        .catch((err) => {
          this.loading = false
          this.loadingSpinner = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
          this.$router.back()
        })
    },
    exibirDetalheProposta(data) {
      this.$router.push({
        name: 'proposta-detalhe',
        params: { idproposta: data.propostaConsignacao.id },
      })
    },

    filtrarStatusConsignacao() {
      for (const consignacao of this.store.dados.consignacoes) {
        if (this.ativos.includes(consignacao.statusConsignacao)) {
          this.ativosList.push(consignacao)
        } else if (this.cancelados.includes(consignacao.statusConsignacao)) {
          this.canceladosList.push(consignacao)
        } else if (this.quitados.includes(consignacao.statusConsignacao)) {
          this.quitadosList.push(consignacao)
        } else {
          this.outrosList.push(consignacao)
        }
      }
    },

    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        statusConsignacao: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        'rubrica.rubrica': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    cancelarConsignacao(data) {
      this.$router.push({
        name: 'cancelar-consignacao',
        params: { idconsig: data.id },
      })
    },

    exibirDetalheConsignacao(data) {
      this.consignacaoDetalheDialog = true
      this.consignacao = data
      this.$router.push({
        name: 'consignacao-detalhe',
        params: { idconsig: data.id },
      })
    },

    exibirBotaoCancelar(data) {
      const dataAtual = new Date()
      const dataCorte = new Date(
        data.anoReferencia,
        data.mesReferencia - 1,
        this.store.dados.entidade.diaCorte,
      )

      if (
        (this.$auth.hasRoleGestao() || this.$auth.hasRoleGestaoEntidade()) &&
        (data.statusConsignacao === 'ATIVA' ||
          data.statusConsignacao === 'NAO_DESCONTADA' ||
          data.statusConsignacao === 'DESCONTO_A_MENOR' ||
          data.statusConsignacao === 'FORA_MARGEM' ||
          (data.statusConsignacao === 'PENDENTE' &&
            dataAtual.getTime() <= dataCorte.getTime()))
      ) {
        return true
      } else return false
    },

    exibirBotaoReincluir(data) {
      const dataAtual = new Date()
      const dataCorte = new Date(
        data.anoReferencia,
        data.mesReferencia - 1,
        this.store.dados.entidade.diaCorte,
      )

      if (
        this.$auth.hasRoleGestao() &&
        (data.statusConsignacao === 'SUSPENSA' ||
          (data.statusConsignacao === 'SOLICITADA_SUSPENSAO' &&
            dataAtual.getTime() <= dataCorte.getTime()))
      ) {
        return true
      } else return false
    },

    exibirHistorico(historico) {
      this.historico = historico
      this.showHistoricoDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .p-sortable-column-icon {
  margin: 0 0 0 0 !important;
}
.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
.ativa {
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}
.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
}
.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
}
.quitada {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
}

.linkProposta {
  margin-bottom: 5px;
  background-color: rgb(240, 158, 8) !important;
}
</style>
